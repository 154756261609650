import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Link, Section, BlogPostsGrid, BlogHeader } from 'components';
import * as styles from './category.module.scss';

const Category = ({ data, pageContext }) => {
  const {
    categoryPosts: { nodes: posts },
    categories: { nodes: categoryArray },
    lpData: { blogFeatureImage, blogMetaDescription, blogMetaTitle },
  } = data;

  const {
    title,
    slug: { current: uid },
  } = pageContext;

  const seo = {
    title: blogMetaTitle,
    desc: blogMetaDescription,
  };

  return (
    <Layout seo={seo}>
      <BlogHeader title={title} currentPageName={title} currentPageUid={uid} image={blogFeatureImage} />
      <Section containerClassName={styles.blogContainer}>
        <BlogSideBar categories={categoryArray} activeCategory={title} />
        <BlogPostsGrid posts={posts} />
      </Section>
    </Layout>
  );
};

const BlogSideBar = ({ categories, activeCategory }) => (
  <div className={styles.sidebarContainer}>
    <div className={styles.sidebar}>
      <span className={styles.categoryTitle}>Categories</span>
      <div className={styles.categoriesContainer}>
        <Link to="/news" className={styles.category}>
          All
        </Link>
        {categories.map((item) => {
          const { title, slug } = item;
          const isActive = title === activeCategory ? styles.activeCategory : '';
          return (
            <Link to={`/news/${slug.current}`} className={`${isActive} ${styles.category}`}>
              {title}
            </Link>
          );
        })}
      </div>
    </div>
  </div>
);

export default Category;

export const query = graphql`
  query Category($title: String!) {
    categoryPosts: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { categories: { elemMatch: { title: { eq: $title } } } }
    ) {
      nodes {
        categories {
          title
        }
        mainImage {
          image {
            asset {
              id
              url
              gatsbyImageData(layout: CONSTRAINED, width: 700, placeholder: BLURRED)
            }
          }
        }
        postAuthorRef {
          authorName
        }
        title
        subtitle
        body {
          children {
            text
          }
        }
        slug {
          current
        }
      }
    }
    categories: allSanityPostCategory {
      nodes {
        title
        slug {
          current
        }
      }
    }
    lpData: sanityBlogLandingPage {
      blogFeatureImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
        }
      }
      blogMetaDescription
      blogMetaTitle
    }
  }
`;
